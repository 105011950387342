import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const buttonResets = `
  border: none;
  background: none;
  cursor: pointer;
  font: inherit;
  outline: none;
  padding: 0;
`;

const StyledButton = styled.button.attrs((props) => {
  const { className, active, focused, hovered } = props;

  return {
    className: classNames(className, "webdex-ui-button", {
      "webdex-ui-button--is-active": active,
      "webdex-ui-button--is-focused": focused,
      "webdex-ui-button--is-hovered": hovered,
    }),
  };
})`
  ${buttonResets}
  ${({ theme: { colors, fontSizes, fontWeights, radii, space }, invert }) => `
    width: 100%;
    padding: ${space.small} ${space.medium};
    border-radius: ${radii.pill};
    font-size: ${fontSizes.small};
    font-weight: ${fontWeights.bold};
    background-color: ${invert ? colors.black : colors.white};
    color: ${invert ? colors.white : colors.black};
    outline: none;
    text-decoration: none;

    // Feels hacky right now, may want to fix later
    @media screen and (max-width: 380px) {
      padding: ${space.small} ${space.small};
    }

    &:hover {
      color: ${colors.black};
      background-color: ${colors.yellow};
    }

    &:active, &.webdex-ui__button--is-active,
    &:hover, &.webdex-ui__button--is-hovered
    {
      color: ${colors.black};
      background-color: ${colors.yellow};
    }

    // :focus managed by KeyboardFocusContainer
    &.webdex-ui-button--is-focused
    {
      color: ${colors.black};
      background-color: ${colors.yellow};
    }
  `}
`;

const Anchor = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledButton
        autoFocus={false}
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused,
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Anchor.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  forceUnderline: PropTypes.bool,
  hovered: PropTypes.bool,
  invert: PropTypes.bool,
};

Anchor.defaultProps = {
  active: false,
  focused: false,
  forceUnderline: false,
  hovered: false,
  invert: false,
};

export default Anchor;
