import React from "react";

import PlayerInnerWrapper from "./views/PlayerInnerWrapper";
import PlayerWrapper from "./views/PlayerWrapper";

const Video = props => {
  const {
    videoIntroduction: {
      video: {
        asset: {
          url: videoSrc
        }
      },
      videoHeight,
      videoWidth
    }
  } = props;

  return (
    <PlayerWrapper
      height={videoHeight}
      width={videoWidth}
      thumbnailSrc={""}
    >
      <PlayerInnerWrapper>
        <video autoPlay playsInline loop controls={true}>
          <source
            src={videoSrc}
            type="video/mp4"
          />
          <track/>
        </video>
      </PlayerInnerWrapper>
    </PlayerWrapper>
  );
};

export default Video;
