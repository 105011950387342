import classNames from "classnames";
import styled from "styled-components";

const PlayerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(
      className,
      "video-introduction-page__player-wrapper"
    )
  };
})`

  ${({ theme: { space }, height, width, thumbnailSrc }) => `
    height: 100%;
    overflow: hidden;
    padding-top: ${ space.xSmall };
    position: relative;
    width: 100%;

    &:before {
      background-image: url(${ thumbnailSrc });
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  `}
`;

PlayerWrapper.defaultProps = {};

export default PlayerWrapper;
