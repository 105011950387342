import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledButton = styled.button.attrs((props) => {
  const { className, active, focused, forceUnderline, hovered } = props;

  return {
    className: classNames(className, "webdex-ui-black-button", {
      "webdex-ui-black-button--is-active": active,
      "webdex-ui-black-button--is-focused": focused,
      "webdex-ui-black-button--is-hovered": hovered,
      "webdex-ui-black-button--force-underline": forceUnderline,
    }),
  };
})`
  ${() => `
    border: none; 
    margin: 0;
    overflow: visible;
    text-transform: none;
    background: #000000;
    border-radius: 20px;
    color: #FFFFFF; 
    cursor: pointer;
    outline: none;
    text-decoration: none;
    width: 400px;
    height: 40px; 
    font-size: 12px;
    line-height: 40px;
    text-transform: uppercase; 

    &:active, &.webdex-ui__black-button--is-active,
    &:hover, &.webdex-ui__black-button--is-hovered
    {
      text-decoration: underline;
    }

    // :focus managed by KeyboardFocusContainer
    &.webdex-ui-black-button--is-focused
    {
      text-decoration: underline;
    }

    &.webdex-ui-black-button--force-underline {
      text-decoration: underline;
    }
  `}
`;

const BlackButton = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledButton
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused,
        })}
      />
    )}
  </KeyboardFocusContainer>
));

BlackButton.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  forceUnderline: PropTypes.bool,
  hovered: PropTypes.bool,
};

BlackButton.defaultProps = {
  active: false,
  focused: false,
  forceUnderline: false,
  hovered: false,
};

export default BlackButton;
