import classNames from "classnames";
import { media } from "styled-bootstrap-grid";
import styled from "styled-components";

const VideoIntroductionInnerWrapper = styled.div.attrs(props => {
  const {
    className
  } = props;

  return {
    className: classNames(className, "video-introduction-page__inner-wrapper")
  };
})`
  ${({ theme: { chrome } }) => `
    display: flex;
    flex-direction: column;
    height: calc(100vh - ${ chrome.mobile.footerHeight });
  `}

  ${media.sm`
    ${ ({ theme: { chrome } }) => `
      && {
        height: calc(100vh - ${ chrome.footerHeight });
      }
    `}
  `}
`;

VideoIntroductionInnerWrapper.propTypes = {};

VideoIntroductionInnerWrapper.defaultProps = {};

export default VideoIntroductionInnerWrapper;
