import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import styled from "styled-components";
import { KeyboardFocusContainer } from "@zendeskgarden/container-keyboardfocus";

const StyledAnchor = styled.a.attrs(
  props => {
    const {
      className,
      active,
      focused,
      forceUnderline,
      hovered
    } = props;

    return {
      className: classNames(className, "webdex-ui-anchor", {
        "webdex-ui-anchor--is-active": active,
        "webdex-ui-anchor--is-focused": focused,
        "webdex-ui-anchor--is-hovered": hovered,

        "webdex-ui-anchor--force-underline": forceUnderline
      })
    }
  }
)`
  ${() => `
    color: inherit;
    cursor: pointer;
    outline: none;
    text-decoration: none;

    &:active, &.webdex-ui__anchor--is-active,
    &:hover, &.webdex-ui__anchor--is-hovered
    {
      text-decoration: underline;
    }

    // :focus managed by KeyboardFocusContainer
    &.webdex-ui-anchor--is-focused
    {
      text-decoration: underline;
    }

    &.webdex-ui-anchor--force-underline {
      text-decoration: underline;
    }
  `}
`;

const Anchor = React.forwardRef(({ focused, ...other }, ref) => (
  <KeyboardFocusContainer>
    {({ getFocusProps, keyboardFocused }) => (
      <StyledAnchor
        {...getFocusProps({
          ref,
          ...other,
          focused: focused || keyboardFocused
        })}
      />
    )}
  </KeyboardFocusContainer>
));

Anchor.propTypes = {
  active: PropTypes.bool,
  focused: PropTypes.bool,
  forceUnderline: PropTypes.bool,
  hovered: PropTypes.bool
};

Anchor.defaultProps = {
  active: false,
  focused: false,
  forceUnderline: false,
  hovered: false
};

export default Anchor;
