import React from "react";
import { graphql } from "gatsby";

import VideoIntroductionPageComponent from "@webdex/Pages/VideoIntroduction";

const VideoIntroduction = props => {
  const { data } = props;

  return (
    <VideoIntroductionPageComponent data={data}/>
  );
};

export const query = graphql`
  query VideoIntroductionPage {
    videoIntroductionPage: sanityVideoIntroductionPage {
      ...fieldsOnVideoIntroductionPage
    }
    videoIntroduction: sanityVideoIntroduction {
      ...fieldsOnVideoIntroduction
    }
  }
`;

export default VideoIntroduction;
