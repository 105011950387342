import React, { forwardRef } from "react";
import { navigate } from "gatsby";

import { Button } from "@webdex/UI/Button";
import { Row, Col } from "@webdex/UI/Grid";
import { ResponsiveText } from "@webdex/UI/Typography";

import StyledHeader from "./views/Header";
import HomeButtonWrapper from "./views/HomeButtonWrapper";

const Header = forwardRef((props, ref) => {
  const goHome = () => {
    navigate("/");
  };

  return (
    <StyledHeader ref={ref}>
      <Row>
        <Col col="8">
          <HomeButtonWrapper>
            <Button role="link" onClick={goHome}>
              <ResponsiveText
                as="span"
                sm="small"
                xs="xSmall"
                weight="bold"
              >
                Close
              </ResponsiveText>
            </Button>
          </HomeButtonWrapper>
        </Col>
      </Row>
    </StyledHeader>
  );
});

export default Header;

