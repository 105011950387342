import React from "react";

import { Metadata } from "@webdex/Core";
import { Col, Row } from "@webdex/UI/Grid";

import Header from "./components/Header";
import Video from "./components/Video";
import StyledVideoIntroduction from "./views/VideoIntroduction";
import VideoIntroductionInnerWrapper from "./views/VideoIntroductionInnerWrapper";

const VideoIntroduction = props => {
  const {
    data: {
      videoIntroductionPage: {
        metadata
      },
      videoIntroduction
    }
  } = props;

  return (
    <React.Fragment>
      <Metadata metadata={metadata}/>
      <StyledVideoIntroduction>
        <Row>
          <Col col="8">
            <VideoIntroductionInnerWrapper>
              <Header/>
              <Video videoIntroduction={videoIntroduction}/>
            </VideoIntroductionInnerWrapper>
          </Col>
        </Row>
      </StyledVideoIntroduction>
    </React.Fragment>
  );
};

export default VideoIntroduction;
